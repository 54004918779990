import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { ISegment } from '../../../types';
import { useAuthContext } from '../../../providers/auth/hooks/useAuthContext';
import { getSegmentAPI, updateSegmentAPI } from '../../../api';
import { SegmentForm } from '../../../components/forms';

const OrganizationEditSegment: FC = () => {
  const { token } = useAuthContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organizationId = '', segmentId = '' } = useParams();

  const [segment, setSegment] = useState<ISegment>();
  const [organization, setOrganization] = useState<{ id: string; name: string }>();

  useEffect(() => {
    (async () => {
      try {
        if (!token || !organizationId || !segmentId) return;

        const response = await getSegmentAPI(token, organizationId, segmentId);

        if (response.code) return;

        setOrganization(response.organization);
        setSegment(response);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const updateSegmentHandler = async (data: any) => {
    try {
      if (!token || !organizationId) {
        console.error('You miss token or organizationId');
        return;
      }

      const res = await updateSegmentAPI(token, organizationId, segmentId, data);

      if (res.code) {
        return;
      }

      navigate(`/organization/${organizationId}/segments`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb breadcrumb-default-icon">
          <li className="breadcrumb-item">
            <Link to={`/organization/${organizationId}/segments`}>Segments</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {`Edit the ${segment?.name}`}
          </li>
        </ol>
      </nav>

      <div className="page-header-title">
        <h3 className="m-b-10">{organization?.name}</h3>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <h5>Segment Info</h5>
          </div>
        </div>
        <div className="card-body">
          {segment ? <SegmentForm onSuccessSubmit={updateSegmentHandler} initialValues={segment} /> : null}
        </div>
      </div>
    </>
  );
};

export default OrganizationEditSegment;
