import { FC, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { flatten, safeParse as valibotSafeParse } from 'valibot';

import { ISegment } from '../../../types';
import { SegmentsFormSchema } from '../../../schemes';
import { getUnifiedFormData } from '../../../lib/getUnifiedFormData';
import { FormElementError } from '../../common';

interface IProps {
  onSuccessSubmit?: (data: any) => Promise<void>;
  initialValues?: ISegment;
}
export const SegmentForm: FC<IProps> = (props) => {
  const { onSuccessSubmit, initialValues } = props;
  const { t } = useTranslation();

  const [formErrors, setFormErrors] = useState<any>();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  // const [isFormSuccess, setIsFormSuccess] = useState<boolean>(false);

  const [isOptInDaysAgoActive, setIsOptInDaysAgoActive] = useState<boolean>(!!initialValues?.opt_in_days_ago);
  const [isLastDeliveredDaysActive, setIsLastDeliveredDaysActive] = useState<boolean>(
    !!initialValues?.last_delivered_msg_ago
  );
  const [isVisitedPageActive, setIsVisitedPageActive] = useState<boolean>(!!initialValues?.visited_page);

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const target = event.target as HTMLFormElement;

    const parseFormData = getUnifiedFormData(target);

    // console.log('parseFormData', parseFormData);

    const validate = valibotSafeParse(SegmentsFormSchema, parseFormData);

    // console.log('validate', validate);

    if (validate.success) {
      setFormErrors(undefined);

      try {
        setIsFormSubmitting(true);

        await onSuccessSubmit?.(validate.output);
      } catch (error) {
        console.error(error);
      } finally {
        setIsFormSubmitting(false);
      }
    } else {
      const flattenErrors = flatten<typeof SegmentsFormSchema>(validate.issues);
      setFormErrors(flattenErrors.nested);
      // console.log('formErrors', flattenErrors.nested);
    }
  };

  const optInDaysAgoToggler = () => {
    setIsOptInDaysAgoActive(!isOptInDaysAgoActive);
  };

  const lastDeliveryDaysToggler = () => {
    setIsLastDeliveredDaysActive(!isLastDeliveredDaysActive);
  };

  const visitedPageToggler = () => {
    setIsVisitedPageActive(!isVisitedPageActive);
  };

  return (
    <form id="segment-form" onSubmit={submitHandler}>
      <div className="mb-3">
        <label className="form-label" htmlFor="segment-name">
          {t('name')}:
        </label>
        <input
          type="text"
          id="segment-name"
          className={'form-control' + (formErrors?.name?.length ? ' is-invalid' : '')}
          name="name"
          placeholder="Enter the name"
          defaultValue={initialValues?.name}
        />
        {formErrors?.name?.length ? <FormElementError text={formErrors.name[0] || ''} /> : null}
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="segment-description">
          {t('description')}:
        </label>
        <input
          type="text"
          id="segment-description"
          className={'form-control' + (formErrors?.description?.length ? ' is-invalid' : '')}
          name="description"
          placeholder="Enter the description"
          defaultValue={initialValues?.description}
        />
        {formErrors?.description?.length ? <FormElementError text={formErrors.description[0] || ''} /> : null}
      </div>

      <div className="card mx-n4 shadow-none mb-0">
        <div className="card-header">
          <h5>Filters</h5>
        </div>
        <div className="card-body">
          <div className="border p-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="mb-0">Opt-in Date</h5>
                <p className="text-muted mb-0">Description</p>
              </div>
              <div className="form-check form-switch form-check-reverse">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="opt_in_days_ago_is_checked"
                  onChange={optInDaysAgoToggler}
                  checked={isOptInDaysAgoActive}
                />
              </div>
            </div>
            <div className={'mt-2' + (isOptInDaysAgoActive ? '' : ' d-none')}>
              <div className="">
                <input
                  type="number"
                  id="opt-in-days-ago"
                  className={'form-control' + (formErrors?.opt_in_days_ago?.length ? ' is-invalid' : '')}
                  name="opt_in_days_ago"
                  min={0}
                  placeholder="Days"
                  defaultValue={initialValues?.opt_in_days_ago}
                />
                {formErrors?.opt_in_days_ago?.length ? (
                  <FormElementError text={formErrors.opt_in_days_ago[0] || ''} />
                ) : null}
              </div>
            </div>
          </div>

          <div className="border p-3 mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="mb-0">Last Delivered</h5>
                <p className="text-muted mb-0">Description</p>
              </div>
              <div className="form-check form-switch form-check-reverse">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="last_delivered_msg_ago_is_checked"
                  onChange={lastDeliveryDaysToggler}
                  checked={isLastDeliveredDaysActive}
                />
              </div>
            </div>
            <div className={'mt-2' + (isLastDeliveredDaysActive ? '' : ' d-none')}>
              <div className="">
                <input
                  type="number"
                  id="last-delivered-msg-ago"
                  className={'form-control' + (formErrors?.last_delivered_msg_ago?.length ? ' is-invalid' : '')}
                  name="last_delivered_msg_ago"
                  min={0}
                  placeholder="Days"
                  defaultValue={initialValues?.last_delivered_msg_ago}
                />
                {formErrors?.last_delivered_msg_ago?.length ? (
                  <FormElementError text={formErrors.last_delivered_msg_ago[0] || ''} />
                ) : null}
              </div>
            </div>
          </div>

          <div className="border p-3 mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="mb-0">Visited Page</h5>
                <p className="text-muted mb-0">Description</p>
              </div>
              <div className="form-check form-switch form-check-reverse">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="visited_page_is_checked"
                  onChange={visitedPageToggler}
                  checked={isVisitedPageActive}
                />
              </div>
            </div>
            <div className={'mt-2' + (isVisitedPageActive ? '' : ' d-none')}>
              <div className="">
                <input
                  type="tex"
                  id="visited-page"
                  className={'form-control' + (formErrors?.visited_page?.length ? ' is-invalid' : '')}
                  name="visited_page"
                  placeholder="https://"
                  defaultValue={initialValues?.visited_page}
                />
                {formErrors?.visited_page?.length ? <FormElementError text={formErrors.visited_page[0] || ''} /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="position-relative text-right">
        <button className="btn btn-primary" type="submit" disabled={isFormSubmitting}>
          {t('save')}
        </button>
      </div>
    </form>
  );
};
