import React, { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Slider from 'rc-slider';
import { flatten, safeParse as valibotSafeParse } from 'valibot';

import {
  IContactPackage,
  IOrgCommands,
  IPaymentCard,
  IPendingItem,
  IVCardDTO,
  IVCard,
  OrganizationDefaultValuesDTO,
  TDaysOfTheWeek,
  IOrganizationDefaultValues,
} from '../../types';
import { mapContactPackages, mapOrgDefaultValues, mapPaymentCards, mapPendingItems, mapVCard } from '../../lib/mappers';
import {
  campaignsAPICreateCommand,
  campaignsAPIUpdateCommand,
  campaignsAPIDeleteCommand,
  getOrganizationDetailsDataAPI,
  buyPackage,
  getOrganizationPackages,
} from '../../api';
import { useAuthContext } from '../../providers/auth/hooks/useAuthContext';
import { CommandsFormSchema } from '../../schemes';
import { getUnifiedFormData } from '../../lib/getUnifiedFormData';
import { metaPixelPurchaseEvent } from '../../lib/analytics/metaPixelEvents';
import { firebasePurchaseEvent } from '../../lib/analytics/firebaseEvents';
import Spinner from './common/Spinner';
import ErrorPopup from '../../components/errors/ErrorPopup';
import { ConfirmModal, FormElementError, HighlightedCode, AttachmentPreview, Tooltip } from '../../components/common';
import Toast from '../../components/toasts/Toast';
import { OrgDefaultValuesForm, VCardForm } from '../../components/forms';
import 'rc-slider/assets/index.css';

interface CommandActionOption {
  label: 'unsubscribe' | 'help';
  value: number;
}

const commandActionOptions: CommandActionOption[] = [
  { label: 'unsubscribe', value: 1 },
  { label: 'help', value: 2 },
];

interface OrganizationDetailsDTO {
  contacts_count: number;
  country: string;
  country_name: string;
  default_values: OrganizationDefaultValuesDTO;
  id: string;
  manage_payment: boolean;
  max_contacts: number;
  name: string;
  next_bill_date: string;
  opt_in_count: number;
  opt_outs_count: number;
  opt_in_url: string;
  payment: boolean;
  pending_contacts: number;
  status: string;
  support_url: string;
  tracker: { code: string };
  vcard: IVCardDTO;
  website: string;
}

const OrganizationDetails: React.FC = () => {
  const { token } = useAuthContext();
  const { t } = useTranslation();
  const { id: organizationId = '' } = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<OrganizationDetailsDTO | undefined>(undefined);
  const [orgDefaultValues, setOrgDefaultValues] = useState<IOrganizationDefaultValues | null>(null);
  const [vCard, setVCard] = useState<IVCard | null>(null);
  const [isPopup, setIsPopup] = useState(false);
  const [errorText, setErrorText] = useState('');
  // const [days, setDays] = useState<TDaysOfTheWeek[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [isCopied, setCopied] = useState(false);
  const [contacts, setContacts] = useState<IContactPackage[]>([]);
  const [paymentCards, setPaymentCards] = useState<IPaymentCard[]>([]);
  const [pendingItems, setPendingItems] = useState<IPendingItem[]>([]);
  const [sliderMarks, setSliderMarks] = useState<[number, string | number][]>([]);
  const [selectedContactsItem, setSelectedContactsItem] = useState<IContactPackage>();
  const [selectedCardItem, setSelectedCardItem] = useState<IPaymentCard>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSuccessAlertShown, setIsSuccessAlertShown] = useState<boolean>(false);
  const [isBuyPackageLoading, setIsBuyPackageLoading] = useState<boolean>(false);
  const [vCardEditMode, setVCardEditMode] = useState<boolean>(false);
  const [selectedCommandItem, setSelectedCommandItem] = useState<IOrgCommands>();
  const [selectedCommandItemId, setSelectedCommandItemId] = useState<string>('');

  // const aaa: Partial<Record<
  //   TSchema ? IssueDotPath<TSchema> : string,
  //   [string, ...string[]]
  //   >>;

  const [formErrors, setFormErrors] = useState<any>();
  // const [formErrors, setFormErrors] = useState<
  //   Partial<Record<keyof IVCardForm | keyof IDefaultValuesForm, [string, ...string[]]>> | undefined
  // >();
  const [orgDefaultValuesEditMode, setOrgDefaultValuesEditMode] = useState<boolean>(false);
  const [commandsLoading, setCommandsLoading] = useState<boolean>(false);
  const [commandsSuccessSaved, setcommandsSuccessSaved] = useState<boolean>(false);

  const [isConfirmDeleteCommandModalShown, setIsConfirmDeleteCommandModalShown] = useState<boolean>(false);
  const [isDeletingCommand, setIsDeletingCommand] = useState<boolean>(false);

  const getData = useCallback(() => {
    (async () => {
      if (!token) return;
      try {
        const res = await getOrganizationDetailsDataAPI(organizationId, token);
        setData(res);
        if (res.default_values) {
          setOrgDefaultValues(mapOrgDefaultValues(res.default_values));
        }
        if (res.vcard) {
          setVCard(mapVCard(res.vcard));
        }
        if (res.payment) {
          fetchOrganizationPackages();
        }

        if (res.code) {
          setIsPopup(true);
          setErrorText(`${res.message}. Error code: ${res.code}`);
        }
        if ((!res.success && res.status && res.status !== 200) || !res) {
          setIsPopup(true);
          setErrorText(res.status ? `Something went wrong. Error code: ${res.status}` : 'Something went wrong.');
        }
        // if (res.status === 'active') {
        //   setData(res);
        // } else if ((!res.success && res.status && res.status !== 200) || !res) {
        //   setIsPopup(true);
        //   setErrorText(res.status ? `Something went wrong. Error code: ${res.status}` : 'Something went wrong.');
        // } else {
        //   setData(res);
        // }
      } catch (e) {
        setError(e as Error);
        console.log(error);
      } finally {
        setLoading(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!orgDefaultValuesEditMode) {
      getData();
    }
  }, [orgDefaultValuesEditMode]);

  const weekDays = useMemo(() => {
    return orgDefaultValues?.weekDays.map<TDaysOfTheWeek>((day) => t(`weekdays.${day}`));
  }, [orgDefaultValues?.weekDays.length, t]);

  const fetchOrganizationPackages = async () => {
    if (!token) return;
    try {
      const response = await getOrganizationPackages(token, organizationId);

      if (response.code || response.error) return;

      const _contacts = mapContactPackages(response.items);
      const _paymentMethods = mapPaymentCards(response.payment_methods.items);
      const _pendingItems = mapPendingItems(response.pending_item);
      setContacts(_contacts);
      // @ts-expect-error Type '(string | number)[]' is not assignable to type '[number, string | number]'.
      setSliderMarks([[0, response.current_contacts], ..._contacts.map((p) => [p.items, `+${p.items}`])]);
      setPaymentCards(_paymentMethods);
      setPendingItems(_pendingItems);
      setSelectedContactsItem(_contacts[0]);
      setSelectedCardItem(_paymentMethods[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const popupClose = useCallback((c: boolean) => {
    setIsPopup(c);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [isCopied]);

  const contactsChangeHandler = (value: number) => {
    const item = contacts.find((c) => c.items === value);
    setSelectedContactsItem(item);
  };
  const paymentMethodChangeHandler = (event: ChangeEvent) => {
    const target = event.target as HTMLSelectElement;
    const value = target.value;

    setSelectedCardItem(paymentCards.find((c) => c.id === value));
  };

  const onDoPayment = async () => {
    if (!token) return;
    try {
      setIsBuyPackageLoading(true);

      const res = await buyPackage(token, {
        organizationId: organizationId,
        packageId: selectedContactsItem!.id!,
        paymentMethodId: selectedCardItem!.id!,
      });

      if (res.success) {
        getData();
        setIsSuccessAlertShown(true);
        setIsOpen(false);

        metaPixelPurchaseEvent(selectedContactsItem!.currency, selectedContactsItem!.amount);
        firebasePurchaseEvent({
          transaction_id: selectedContactsItem!.id,
          value: Number(selectedContactsItem!.amount),
          currency: selectedContactsItem!.currency,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsBuyPackageLoading(false);
    }
  };

  const failureSubmit = (res: any) => {
    setIsPopup(true);
    setErrorText(`${res.message}. Error code: ${res.code}`);
    return;
  };

  const submitCommandFormHandler = async (e: FormEvent) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const parseFormData = getUnifiedFormData(target);

    console.log('parseFormData', parseFormData);

    const validate = valibotSafeParse(CommandsFormSchema, parseFormData);

    console.log('validate', validate);

    if (validate.success) {
      setFormErrors(undefined);

      try {
        if (!token) return;

        setCommandsLoading(true);

        const response = selectedCommandItem
          ? await campaignsAPIUpdateCommand(
              { orgId: organizationId, commandId: selectedCommandItem.id },
              token,
              validate.output.command
            )
          : await campaignsAPICreateCommand(organizationId, token, validate.output.command);

        if (response.code) {
          setIsPopup(true);
          setErrorText(`${response.message}. Error code: ${response.code}`);
          return;
        }

        setData({
          ...data,
          // @ts-expect-error: Types of property 'available_timezones' are incompatible
          default_values: {
            ...data?.default_values,
            commands: response.commands,
          },
        });
        setcommandsSuccessSaved(true);
        setSelectedCommandItem(undefined);

        setTimeout(() => {
          setcommandsSuccessSaved(false);
        }, 3000);

        target.reset();
      } catch (error) {
        setError(error as Error);
        console.error(error);
      } finally {
        setCommandsLoading(false);
      }
    } else {
      const flattenErrors = flatten<typeof CommandsFormSchema>(validate.issues);
      setFormErrors(flattenErrors.nested);
      console.log('formErrors', flattenErrors.nested);
    }
  };

  const deleteCommandHandler = async (id: string) => {
    if (!token) return;

    try {
      setIsDeletingCommand(true);

      const response = await campaignsAPIDeleteCommand({ orgId: organizationId, commandId: id }, token);

      if ('commands' in response) {
        setData({
          ...data,
          // @ts-expect-error: Types of property 'available_timezones' are incompatible
          default_values: {
            ...data?.default_values,
            commands: response.commands,
          },
        });
        setIsConfirmDeleteCommandModalShown(false);
      }

      if (response.code) {
        setIsPopup(true);
        setErrorText(`${response.message}. Error code: ${response.code}`);
      }
    } catch (error) {
      setError(error as Error);
      console.error(error);
    } finally {
      setIsDeletingCommand(false);
    }
  };

  const editCommandHandler = (item: any) => {
    setSelectedCommandItem(item);
  };

  const cancelDeletionCommandHandler = () => {
    setIsConfirmDeleteCommandModalShown(false);
    setSelectedCommandItemId('');
  };
  const confirmDeleteCommandHandler = (id: string) => {
    setIsConfirmDeleteCommandModalShown(true);
    setSelectedCommandItemId(id);
  };

  return (
    <>
      <div className="page-wrapper">
        {!loading ? <Spinner top={10} /> : null}
        {isPopup ? <ErrorPopup isPopup={isPopup} errorCode={errorText} popupClose={popupClose} /> : null}
        <div className="page-header-title">
          <h3 className="m-b-10">{data?.name}</h3>
        </div>
        <div>
          <div className="row">
            <div className="col-md-4 col-xl-4">
              <div className="card">
                <div className="card-block">
                  <h6 className="mb-4">{t('contacts')}</h6>
                  <div className="row d-flex">
                    <div className="col-12">
                      <div className="d-flex align-items-center w-100">
                        <h3 className="f-w-300 m-b-0">
                          {data?.contacts_count} / {data?.max_contacts}
                        </h3>
                        {data?.pending_contacts ? (
                          <>
                            <span className="lead text-muted">&nbsp;{`(${data?.pending_contacts})`}</span>
                            <Tooltip
                              className="ml-2"
                              triggerEl={<FeatherIcon className="feather" icon="help-circle" size={18} />}
                              text={<Trans i18nKey="pendingAlertMsg" components={{ strong: <strong /> }} />}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-block">
                  <div>
                    <h6 className="mb-4">{t('optInsLast24Hours')}</h6>
                    <div className="row d-flex align-items-center">
                      <div className="col-12">
                        <div className="d-flex justify-content-between w-100">
                          <h3 className="f-w-300 d-flex align-items-center m-b-0">{data?.opt_in_count}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-block">
                  <div>
                    <h6 className="mb-4">{t('optOutsLas24Hours')}</h6>
                    <div className="row d-flex align-items-center">
                      <div className="col-12">
                        <div className="d-flex justify-content-between w-100">
                          <h3 className="f-w-300 d-flex align-items-center m-b-0">{data?.opt_outs_count}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-block">
              <Link
                className="btn btn-primary"
                to={`/organization/${organizationId}/segments`}
                state={{ organization: { id: organizationId, name: data?.name } }}
              >
                Go to Segments
              </Link>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h5>{t('organizationDetails')}</h5>
            </div>
            <div className="card-body">
              <table className="table">
                <tbody>
                  {data?.name ? (
                    <tr>
                      <td>
                        <FeatherIcon className="feather m-r-5 m-t-3m" icon="briefcase" size={14} /> {t('companyName')}:
                      </td>
                      <td className="text-right">
                        <span>{data?.name}</span>
                      </td>
                    </tr>
                  ) : null}
                  {data?.status ? (
                    <tr className="table-group-divider">
                      <td>
                        <FeatherIcon className="feather m-r-5 m-t-3m" icon="bar-chart-2" size={16} />
                        {t('status')}:
                      </td>
                      <td className="text-right">{t(data?.status)}</td>
                    </tr>
                  ) : null}
                  {data?.website ? (
                    <tr>
                      <td>
                        <FeatherIcon className="feather m-r-5 m-t-3m" icon="cast" size={14} /> {t('website')}:
                      </td>
                      <td className="text-right">{data?.website}</td>
                    </tr>
                  ) : null}
                  {data?.support_url ? (
                    <tr>
                      <td>
                        <FeatherIcon className="feather m-r-5 m-t-3m" icon="link" size={14} /> {t('supportUrl')}:
                      </td>
                      <td className="text-right">{data?.support_url}</td>
                    </tr>
                  ) : null}
                  {data?.country_name ? (
                    <tr>
                      <td>
                        <FeatherIcon className="feather m-r-5 m-t-3m" icon="globe" size={14} /> {t('country')}:
                      </td>
                      <td className="text-right">{data?.country_name}</td>
                    </tr>
                  ) : null}
                  {data?.opt_in_url ? (
                    <tr className="table-group-divider">
                      <td>
                        <FeatherIcon className="feather m-r-5 m-t-3m" icon="link" size={14} /> {t('optInUrl')}:
                      </td>
                      <td className="text-right">
                        <div className="d-flex align-items-center justify-content-end">
                          <div className="hover-text">
                            <CopyToClipboard text={data?.opt_in_url} onCopy={() => setCopied(true)}>
                              <span style={{ cursor: 'pointer', marginRight: 5 }}>
                                <FeatherIcon className="feather m-r-5 m-t-3m" icon="copy" size={14} />
                              </span>
                            </CopyToClipboard>
                            <span className="tooltip-text" id="left">
                              {isCopied ? 'Copied!' : 'Copy'}
                            </span>
                          </div>
                          <span>{data?.opt_in_url}</span>
                        </div>
                        <small className="text-muted">{t('optInUrlHint')}</small>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>

          {data?.tracker ? (
            <div className="card">
              <div className="card-header">
                <h5>{t('tracker')}</h5>
              </div>
              <div className="card-body">
                <p className="m-b-0">{t('trackerDescription')}</p>
                <p>{t('trackerDescription2')}</p>
                <HighlightedCode code={data.tracker.code} />
              </div>
            </div>
          ) : null}

          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5>{t('defaultValues')}</h5>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => setOrgDefaultValuesEditMode(!orgDefaultValuesEditMode)}
                  >
                    <div className=" d-flex align-items-center">
                      <FeatherIcon className="mr-2" icon="edit-2" />
                      {orgDefaultValuesEditMode ? t('cancel') : t('edit')}
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              {orgDefaultValuesEditMode ? (
                <>
                  <OrgDefaultValuesForm
                    organizationId={organizationId}
                    defaultValues={orgDefaultValues}
                    onFailureSubmit={(res) => failureSubmit(res)}
                  />

                  <hr className="my-4" />

                  <form onSubmit={submitCommandFormHandler}>
                    <div className="row">
                      <div className="col-12">
                        <h4 className="mb-4">{t('commands')}</h4>
                      </div>
                      <div className="col-12">
                        <div className="alert alert-info" role="alert">
                          {t('commandsInfoAlert')}.
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="table-border-style">
                          <div className="table-responsive">
                            <table className="table table-hover table-light table-align-center">
                              <thead>
                                <tr>
                                  <th className="bg-gray-300 text-uppercase">ID</th>
                                  <th className="bg-gray-300 text-uppercase">{t('command')}</th>
                                  <th className="bg-gray-300 text-uppercase">{t('subject')}</th>
                                  <th className="bg-gray-300 text-uppercase">{t('text')}</th>
                                  <th className="bg-gray-300 text-uppercase">{t('action')}</th>
                                  <th className="text-end bg-gray-300 text-uppercase">{t('actions')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.default_values.commands.map((command, idx) => (
                                  <tr key={command.id} data-index={command.id}>
                                    <td>{idx + 1}</td>
                                    <td>{command.value}</td>
                                    <td>{command.subject}</td>
                                    <td>{command.text}</td>
                                    <td>{command.action}</td>
                                    <td className="text-end">
                                      {command.editable ? (
                                        <>
                                          <button
                                            type="button"
                                            className="avtar btn btn-icon btn-link-secondary m-r-5"
                                            onClick={() => editCommandHandler(command)}
                                          >
                                            <FeatherIcon className="feather" icon="edit" size={20} />
                                          </button>
                                          <button
                                            type="button"
                                            className="avtar btn btn-icon btn-link-danger m-r-5"
                                            onClick={() => confirmDeleteCommandHandler(command.id)}
                                          >
                                            <FeatherIcon className="feather" icon="trash-2" size={20} />
                                          </button>
                                        </>
                                      ) : null}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="mb-3">
                          <label className="form-label">{t('command')}:</label>
                          <input
                            type="text"
                            className={'form-control' + (formErrors?.['command.value']?.length ? ' is-invalid' : '')}
                            name="command.value"
                            placeholder={t('command')}
                            defaultValue={selectedCommandItem?.value}
                          />
                          {formErrors?.['command.value']?.length ? (
                            <FormElementError text={formErrors['command.value'][0] || ''} />
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="mb-3">
                          <label className="form-label">{t('subject')}:</label>
                          <input
                            type="text"
                            className={'form-control' + (formErrors?.['command.subject']?.length ? ' is-invalid' : '')}
                            name="command.subject"
                            placeholder={t('subject')}
                            defaultValue={selectedCommandItem?.subject}
                          />
                          {formErrors?.['command.subject']?.length ? (
                            <FormElementError text={formErrors['command.subject'][0] || ''} />
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="mb-3">
                          <label className="form-label">{t('text')}:</label>
                          <input
                            type="text"
                            className={'form-control' + (formErrors?.['command.text']?.length ? ' is-invalid' : '')}
                            name="command.text"
                            placeholder={t('text')}
                            defaultValue={selectedCommandItem?.text}
                          />
                          {formErrors?.['command.text']?.length ? (
                            <FormElementError text={formErrors['command.text'][0] || ''} />
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="mb-3">
                          <label className="form-label">{t('action')}:</label>
                          <select
                            className={'form-select' + (formErrors?.['command.action']?.length ? ' is-invalid' : '')}
                            name="command.action"
                            value={selectedCommandItem?.action}
                          >
                            {commandActionOptions.map((action, idx) => (
                              <option key={idx} value={action.value}>
                                {action.label}
                              </option>
                            ))}
                          </select>
                          {formErrors?.['command.action']?.length ? (
                            <FormElementError text={formErrors['command.action'][0] || ''} />
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <button className="btn btn-primary mt-2" type="submit" disabled={commandsLoading}>
                      {commandsLoading ? t('sending') : selectedCommandItem ? t('updateCommand') : t('addCommand')}
                    </button>
                  </form>

                  {commandsSuccessSaved ? (
                    <div className="mt-4">
                      <Toast message={'Command has been added or updated!'} />
                    </div>
                  ) : null}

                  {isConfirmDeleteCommandModalShown ? (
                    <ConfirmModal
                      isOpen={isConfirmDeleteCommandModalShown}
                      title={t('confirmAction')}
                      text={t('confirmDeletion')}
                      onCancel={cancelDeletionCommandHandler}
                      onConfirm={() => deleteCommandHandler(selectedCommandItemId)}
                      confirmText={isDeletingCommand ? 'Deleting...' : 'Yes, delete it'}
                      dangerConfirmButton
                    />
                  ) : null}
                </>
              ) : (
                <table className="table">
                  <tbody>
                    {weekDays?.length ? (
                      <tr>
                        <td>
                          <FeatherIcon className="feather m-r-5 m-t-3m" icon="calendar" size={14} />{' '}
                          {t('daysWeekOfSending')}:
                        </td>
                        <td className="text-right">{weekDays.join(', ')}</td>
                      </tr>
                    ) : null}
                    {data?.default_values.from_time ? (
                      <tr>
                        <td>
                          <FeatherIcon className="feather m-r-5 m-t-3m" icon="clock" size={14} /> {t('fromTime')}:
                        </td>
                        <td className="text-right">
                          <span>{data?.default_values.from_time}</span>
                        </td>
                      </tr>
                    ) : null}
                    {data?.default_values.to_time ? (
                      <tr>
                        <td>
                          <FeatherIcon className="feather m-r-5 m-t-3m" icon="clock" size={14} /> {t('toTime')}:
                        </td>
                        <td className="text-right">
                          <span>{data?.default_values.to_time}</span>
                        </td>
                      </tr>
                    ) : null}
                    {data?.default_values.opt_in_text ? (
                      <tr>
                        <td>
                          <FeatherIcon className="feather m-r-5 m-t-3m" icon="type" size={14} /> {t('optInText')}:
                        </td>
                        <td className="text-right">
                          <span>{data?.default_values.opt_in_text}</span>
                        </td>
                      </tr>
                    ) : null}
                    {data?.default_values.utm_source ? (
                      <tr>
                        <td>
                          <FeatherIcon className="feather m-r-5 m-t-3m" icon="disc" size={14} /> {t('utmSource')}:
                        </td>
                        <td className="text-right">
                          <span>{data?.default_values.utm_source}</span>
                        </td>
                      </tr>
                    ) : null}
                    {data?.default_values.utm_medium ? (
                      <tr>
                        <td>
                          <FeatherIcon className="feather m-r-5 m-t-3m" icon="divide-circle" size={16} />
                          {t('utmMedium')}:
                        </td>
                        <td className="text-right">{data?.default_values.utm_medium}</td>
                      </tr>
                    ) : null}
                    {data?.default_values.utm_campaign ? (
                      <tr>
                        <td>
                          <FeatherIcon className="feather m-r-5 m-t-3m" icon="dribbble" size={14} /> {t('utmCampaign')}:
                        </td>
                        <td className="text-right">{data?.default_values.utm_campaign}</td>
                      </tr>
                    ) : null}
                    {data?.default_values.timezone ? (
                      <tr>
                        <td>
                          <FeatherIcon className="feather m-r-5 m-t-3m" icon="clock" size={14} /> {t('timezone')}:
                        </td>
                        <td className="text-right">{data?.default_values.timezone}</td>
                      </tr>
                    ) : null}
                    {data?.default_values.welcome_message.subject ? (
                      <tr>
                        <td>
                          <FeatherIcon className="feather m-r-5 m-t-3m" icon="type" size={14} />{' '}
                          {`${t('welcomeMessage')} — ${t('subject')}`}:
                        </td>
                        <td className="text-right">{data?.default_values.welcome_message.subject}</td>
                      </tr>
                    ) : null}
                    {data?.default_values.welcome_message.text ? (
                      <tr>
                        <td>
                          <FeatherIcon className="feather m-r-5 m-t-3m" icon="type" size={14} />{' '}
                          {`${t('welcomeMessage')} — ${t('text')}`}:
                        </td>
                        <td className="text-right">{data?.default_values.welcome_message.text}</td>
                      </tr>
                    ) : null}
                    {data?.default_values.welcome_message.attachments.length ? (
                      <tr>
                        <td>
                          <FeatherIcon className="feather m-r-5 m-t-3m" icon="type" size={14} />{' '}
                          {`${t('welcomeMessage')} — ${t('attachments')}`}:
                        </td>
                        <td className="d-flex flex-wrap justify-content-end align-items-center gap-3">
                          {data.default_values.welcome_message.attachments.map((attachment, index) => (
                            <AttachmentPreview key={attachment + index} url={attachment} />
                          ))}
                        </td>
                      </tr>
                    ) : null}
                    {data?.default_values.post_welcome_message.subject ? (
                      <tr>
                        <td>
                          <FeatherIcon className="feather m-r-5 m-t-3m" icon="type" size={14} />{' '}
                          {`${t('postWelcomeMessage')} — ${t('subject')}`}:
                        </td>
                        <td className="text-right">{data?.default_values.post_welcome_message.subject}</td>
                      </tr>
                    ) : null}
                    {data?.default_values.post_welcome_message.text ? (
                      <tr>
                        <td>
                          <FeatherIcon className="feather m-r-5 m-t-3m" icon="type" size={14} />{' '}
                          {`${t('postWelcomeMessage')} — ${t('text')}`}:
                        </td>
                        <td className="text-right">{data?.default_values.post_welcome_message.text}</td>
                      </tr>
                    ) : null}
                    {data?.default_values.post_welcome_message.attachments.length ? (
                      <tr>
                        <td>
                          <FeatherIcon className="feather m-r-5 m-t-3m" icon="type" size={14} />{' '}
                          {`${t('postWelcomeMessage')} — ${t('attachments')}`}:
                        </td>
                        <td className="d-flex flex-wrap justify-content-end align-items-center gap-3">
                          {data.default_values.post_welcome_message.attachments.map((attachment, index) => (
                            <AttachmentPreview key={attachment + index} url={attachment} />
                          ))}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          {data?.payment ? (
            <div className="card">
              <div className="card-header">
                <div className="row row-cols-auto justify-content-between align-items-center">
                  <div className="col-auto">
                    <h5>{t('payment')}</h5>
                  </div>
                  <div className="col-auto">
                    {data?.next_bill_date ? (
                      <span className="text-gray-600">
                        {t('nextBillDate')}:{' '}
                        <strong>
                          {new Intl.DateTimeFormat(navigator.language, {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(data.next_bill_date))}
                        </strong>
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="card-body">
                {isSuccessAlertShown ? (
                  <div className="alert alert-success" role="alert">
                    {t('youHaveBought', { count: selectedContactsItem?.items })}!
                  </div>
                ) : null}
                {paymentCards.length > 0 ? (
                  <form>
                    <div className="mb-5">
                      <label className="form-label mb-4" htmlFor="contacts-select">
                        {t('addContactsToOrg')}
                      </label>
                      {contacts?.length > 0 ? (
                        <Slider
                          className="pc-range-slider"
                          min={sliderMarks[0][0]}
                          max={sliderMarks.at(-1)![0]}
                          defaultValue={sliderMarks[1][0]}
                          marks={Object.fromEntries(sliderMarks)}
                          step={null}
                          onChange={(value) => contactsChangeHandler(value as number)}
                        />
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="payment-method">
                        {t('forms.billing.rows.paymentMethod')}
                      </label>
                      <select id="payment-method" className="form-select" onChange={paymentMethodChangeHandler}>
                        {paymentCards.length > 0
                          ? paymentCards.map((c) => (
                              <option key={c.id} value={c.id}>
                                **** {c.last4Digits}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                    {selectedContactsItem?.prorate?.dayDifference ? (
                      <div>
                        <span className="d-block">
                          <Trans
                            i18nKey="proRateCharge"
                            components={{ b: <b /> }}
                            values={{
                              days: selectedContactsItem.prorate.dayDifference,
                            }}
                          />
                        </span>
                        <small>{t('proRateChargeHint')}</small>
                      </div>
                    ) : null}
                    <div className="d-flex align-items-end mt-4 mb-3">
                      <h5 className="m-b-0">{t('forms.checkout.total')}:</h5>
                      <h3 className="f-w-700 ml-2 mb-0 lh-1">
                        {selectedContactsItem ? selectedContactsItem?.localizedPrice : 0}
                      </h3>
                    </div>
                    <button
                      className="btn btn-primary mt-2"
                      onClick={() => setIsOpen(true)}
                      disabled={!contacts.length || !paymentCards.length || !selectedContactsItem}
                      type="button"
                    >
                      {t('continue')}
                    </button>
                  </form>
                ) : (
                  <div>
                    <p>{t('noPaymentMethods')}.</p>
                    <Link className="btn btn-primary" to="/billing/payment-method">
                      {t('paymentMethodTab.addButton')}
                    </Link>
                  </div>
                )}

                {pendingItems.length > 0 ? (
                  <>
                    <hr className="my-4" />

                    <div>
                      <div className="alert alert-info" role="alert">
                        <Trans i18nKey="pendingAlertMsg" components={{ strong: <strong /> }} />.
                      </div>
                      <div className="table-border-style">
                        <div className="table-responsive">
                          <table className="table table-hover table-light">
                            <thead>
                              <tr>
                                <th className="bg-gray-300 text-uppercase">ID</th>
                                <th className="bg-gray-300 text-uppercase">{t('forms.billing.rows.dateCreate')}</th>
                                <th className="bg-gray-300 text-uppercase">{t('forms.billing.rows.quantity')}</th>
                                <th className="text-end bg-gray-300 text-uppercase">
                                  {t('forms.billing.rows.status')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {pendingItems.map((item, idx) => (
                                <tr key={item.id} data-index={idx}>
                                  <td>{idx + 1}</td>
                                  <td>
                                    {new Intl.DateTimeFormat(navigator.language, {
                                      year: 'numeric',
                                      month: 'short',
                                      day: 'numeric',
                                    }).format(new Date(item.createdAt))}
                                  </td>
                                  <td>{item.credits}</td>
                                  <td className="text-end">
                                    <span className="badge text-bg-warning">{t('pending')}</span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5>{t('vcard.title')}</h5>
                <div>
                  <Link className="btn btn-light-secondary mr-2" to={vCard?.downloadUrl ?? ''} target="_blank">
                    <div className="d-flex align-items-center">
                      <FeatherIcon className="mr-2" icon="download" />
                      {t('vcard.downloadButton')}
                    </div>
                  </Link>
                  <button className="btn btn-primary" onClick={() => setVCardEditMode(!vCardEditMode)}>
                    <div className="d-flex align-items-center">
                      <FeatherIcon className="mr-2" icon="edit-2" />
                      {vCardEditMode ? t('cancel') : t('vcard.editButton')}
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="alert alert-info" role="alert">
                {t('vcard.titleAction')}
              </div>
              {vCardEditMode ? (
                <VCardForm
                  organizationId={organizationId}
                  defaultValues={vCard}
                  onSuccessSubmit={({ vcard }) => setVCard(mapVCard(vcard))}
                  onFailureSubmit={(res) => failureSubmit(res)}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {isOpen ? (
        <ConfirmModal
          isOpen={isOpen}
          title={t('confirmAction')}
          text={
            <Trans
              i18nKey="paymentConfirmText"
              components={{ strong: <strong /> }}
              values={{
                cardType: selectedCardItem?.cardType,
                last4Digits: selectedCardItem?.last4Digits,
                price: selectedContactsItem?.localizedPrice,
              }}
            />
          }
          onCancel={() => setIsOpen(false)}
          onConfirm={onDoPayment}
          confirmText={isBuyPackageLoading ? t('sending') + '...' : t('confirm')}
        />
      ) : null}
    </>
  );
};

export default OrganizationDetails;
